import {_post} from "@api/services/httpService";

export const BLOCK_PRICE_CATEGORY_PHOTO_URL = '/clubs/playgrounds/timetables/blocks/prices/categories/photos';

const headers = { "Content-Type": "multipart/form-data" };

export const postBlockPriceCategoryPhoto = (formData) => _post(BLOCK_PRICE_CATEGORY_PHOTO_URL,
  formData, {
    headers: headers
  })
;

