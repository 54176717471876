import {_post} from "@api/services/httpService";

export const BLOCK_PRICE_URL_PHOTOS = '/clubs/playgrounds/timetables/blocks/prices/photos';

const headers = { "Content-Type": "multipart/form-data" };

export const postBlockPricePhoto = (formData) => _post(BLOCK_PRICE_URL_PHOTOS,
  formData, {
    headers: headers
  })
;

