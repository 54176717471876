import Vue from 'vue';
import * as DateHolidays from 'date-holidays';

const holidaysPlugin = {
  fire () {
    return new DateHolidays.default();
  },

  install(Vue) {
    Vue.prototype.$holidays = this.fire;
  }
}

Vue.use(holidaysPlugin);

export default holidaysPlugin;
