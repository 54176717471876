import {_post} from "@api/services/httpService";
import {ACTIVITIES_PHOTO_URL} from "@api/services/activities/activities.api";
import {IMPORT_CSV_URL} from "@api/services/client-import-file/client-import-file.api";
import {BLOCK_PRICE_URL_PHOTOS} from "@api/services/timetable/blocks/price/photo/photo.api";
import {BLOCK_PRICE_CATEGORY_PHOTO_URL} from "@api/services/timetable/blocks/price/category/photo/category-photo.api";


const headers = {"Content-Type": "multipart/form-data"};

export const getUrlFromTarget = (payload, formData) => {
  const target = payload.target;

  switch (target) {
    case 'postActivityPhoto':
      return ACTIVITIES_PHOTO_URL;
    case 'postBlockPricePhoto':
      return BLOCK_PRICE_URL_PHOTOS;
    case 'postBlockPriceCategoryPhoto':
      return BLOCK_PRICE_CATEGORY_PHOTO_URL;
    case 'postCsvFile':
      return IMPORT_CSV_URL;
  }
}

export const postPhoto = (URL, formData) => _post(URL,
  formData, {
    headers: headers
  })
;
