import Vue from 'vue';
import {getUrlFromTarget, postPhoto} from "@api/services/uploaderService";

const fileManagerPlugin = {
  toDataURL(url) {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  },
  async downloadCSV(csvStr, fileName) {
    const hiddenElement = document.createElement('a');

    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName + '.csv';
    hiddenElement.click();
  },
  async downloadXML(csvStr, fileName) {
    const hiddenElement = document.createElement('a');

    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName + '.xml';
    hiddenElement.click();
  },
  async downloadPDF(response, fileName, date = null) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    const fullName = fileName + (date ? ' ' + date : '') + '.pdf';

    link.href = url;
    link.setAttribute('download', fullName);

    document.body.appendChild(link);
    link.click();
  },
  async download(token, name) {
    const a = document.createElement('a');
    a.href = await fileManagerPlugin.toDataURL(fileManagerPlugin.filePath(token));
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  filePath(token) {
    if (!token) {
      return null;
    }

    const uri = process.env.VUE_APP_UPLOAD_FILE_PATH;

    if (uri) {
      return uri.replace('{token}', token);
    } else {
      return '';
    }
  },
  upload(callback, payload) {
    if (payload === undefined) {
      payload = {};
    }

    const onFileBrowserClose = (file) => {
      document.body.onfocus = null;
      document.body.removeChild(input);
    };

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('id', 'file-upload-id');
    input.setAttribute('style', 'display: none;');
    input.addEventListener('change', (e) => {
      if (e.target.files.length) {
        if (payload.url === null) {
          const file = e.target.files[0];
          callback(file);
        } else {
          this.$uploadFile(e.target.files[0], payload)
            .then(uploadFile => callback(uploadFile))
          ;
        }
      }
    });
    input.addEventListener('click', (e) => {
      document.body.onfocus = onFileBrowserClose;
    });

    if (payload.accept) {
      input.setAttribute('accept', payload.accept);
    }

    document.body.appendChild(input);
    input.click();
  },

  uploadFile(file, payload) {
    payload.file = file;
    let formData = new FormData();

    formData.append("file", file);
    if (null !== payload.url) {
      formData.append(payload.entry, payload.url);
    }

    const fileUploadURl = getUrlFromTarget(payload, formData);

    return postPhoto(fileUploadURl, formData)
      .then(response => response.data)
      .catch(error => console.error('file upload failed', error))
      ;
  },
  install(Vue, options) {
    Vue.prototype.$uploadFile = this.uploadFile;
    Vue.prototype.$upload = this.upload;
    Vue.prototype.$filePath = this.filePath;
    Vue.prototype.$download = this.download;
    Vue.prototype.$downloadCSV = this.downloadCSV;
    Vue.prototype.$downloadXML = this.downloadXML;
    Vue.prototype.$downloadPDF = this.downloadPDF;
  },
};

Vue.use(fileManagerPlugin);

export default fileManagerPlugin;
